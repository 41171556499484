import React from 'react'
import logo from '../assets/images/campaigns/cto-craft-con-logo.png'
import FreeAnalysisLandingPage from '../components/FreeAnalysisLandingPage'

const CtoCraftPage = () => {
  return (
    <FreeAnalysisLandingPage
      header={
        <div
          style={{
            background: '#f4f4f4',
            marginBottom: '64px',
          }}
        >
          <div
            style={{
              margin: '0 auto',
              padding: '16px 0',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src={logo}
              alt="CTO Craft Con 3.0 logo"
              style={{ maxWidth: '100%', height: '64px', display: 'block' }}
            />
          </div>
        </div>
      }
      campaign={{
        value: 'CTO_CRAFT_CON',
        audience: 'CTO Craft Con 3.0 attendee',
      }}
    />
  )
}

export default CtoCraftPage
